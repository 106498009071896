import Alpine from 'alpinejs';
// import intersect from '@alpinejs/intersect';
import Tooltip from "@ryangjchandler/alpine-tooltip";
Alpine.plugin(Tooltip);


import focus from '@alpinejs/focus';
Alpine.plugin(focus)


window.Alpine = Alpine
Alpine.start();
// Alpine.plugin(intersect);

import './smoothscroll';



document.addEventListener('DOMContentLoaded', function() {


    const offsetAnchor = () => {
        if (location.hash.length !== 0) {
            window.scrollTo(window.scrollX, window.scrollY - 100);
        }
    }

    const anchors = document.querySelectorAll('a[href^="#"]')

    for (const a of anchors) {
        a.addEventListener('click', (event) => {
            window.setTimeout(() => {
                offsetAnchor();
            }, 0);
        });
    }

    document.addEventListener('click', (event) => {
        window.setTimeout(() => {
            offsetAnchor();
        }, 0);
    });

    window.setTimeout(offsetAnchor, 0);



});

